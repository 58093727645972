exports.components = {
  "component---src-components-pages-page-index-tsx": () => import("./../../../src/components/pages/Page/index.tsx" /* webpackChunkName: "component---src-components-pages-page-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-airwallex-return-tsx": () => import("./../../../src/pages/airwallex/return.tsx" /* webpackChunkName: "component---src-pages-airwallex-return-tsx" */),
  "component---src-pages-cart-[id]-tsx": () => import("./../../../src/pages/cart/[id].tsx" /* webpackChunkName: "component---src-pages-cart-[id]-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-[id]-tsx": () => import("./../../../src/pages/order/[id].tsx" /* webpackChunkName: "component---src-pages-order-[id]-tsx" */),
  "component---src-pages-order-payment-failed-message-index-tsx": () => import("./../../../src/pages/order/PaymentFailedMessage/index.tsx" /* webpackChunkName: "component---src-pages-order-payment-failed-message-index-tsx" */),
  "component---src-pages-sagepay-secure-tsx": () => import("./../../../src/pages/sagepay/secure.tsx" /* webpackChunkName: "component---src-pages-sagepay-secure-tsx" */),
  "component---src-pages-stripe-return-tsx": () => import("./../../../src/pages/stripe/return.tsx" /* webpackChunkName: "component---src-pages-stripe-return-tsx" */),
  "component---src-pages-tracking-tsx": () => import("./../../../src/pages/tracking.tsx" /* webpackChunkName: "component---src-pages-tracking-tsx" */)
}

