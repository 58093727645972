export const ADDRESS_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment AddressFields on Address {
        id
        business
        firstName
        lastName
        company
        line1
        line2
        city
        postCode
        stateCode
        countryCode
        state
        lat
        long
        what3Words
        deliveryNotes
    }
`;

export const DISCOUNT_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment DiscountFields on Discount {
        id
        name
        enabled
        validFrom
        validTo
        couponCode
        type
        applyTo
        priority
        applyFurtherDiscount
        value
        amountApplied
    }
`;

export const COUNTRY_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment CountryFields on Country {
        alpha2
        alpha3
        name
        officialName
        flagUrl
        enabled
        cb
        sb
        co
        so
    }
`;

export const PAYMENT_GATEWAY_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment PaymentGatewayFields on PaymentGateway {
        id
        name
        enabled
        type
        config {
            property
            value
            propertyId
            salesChannelId
        }
        acceptedCards {
            id
            name
            code
            cardRegex
            icon
        }
    }
`;

export const TRACKING_ORDER_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment OrderFields on Order {
        id
        invNumber
        number
        orderDate
        status
        orderShippingMethod
        shipmentsInvalid
    }
`;

export const ORDER_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment OrderFields on Order {
        id
        invNumber
        number
        orderDate
        errors
        gtagClientId
        customer {
            id
            firstName
            lastName
            email
            phoneNumber
        }
        status
        paymentStatus
        languageCode
        currencyCode
        isShipped
        billingAddress {
            id
            business
            firstName
            lastName
            company
            line1
            line2
            city
            postCode
            stateCode
            countryCode
            state
            lat
            long
            what3Words
            deliveryNotes
        }
        shippingAddress {
            id
            business
            firstName
            lastName
            company
            line1
            line2
            city
            postCode
            stateCode
            countryCode
            state
            lat
            long
            what3Words
            deliveryNotes
        }
        guest
        subTotalAmount
        subTotalTax
        shippingAmount
        shippingTaxAmount
        paymentMethodAmount
        paymentMethodAmountTax
        discountAmount
        discountTaxAmount
        discountsApplied {
            id
            name
            amountApplied
        }
        totalAmount
        creditUsed
        totalWeight
        totalTax
        dutyAmount
        dutyAmountTax
        itemsCount
        shipmentsCount
        orderNotes
        couponsUsed {
            couponCode
        }
        orderItems {
            id
            skuId
            code
            parentId
            quantity
            name
            unitAmount
            totalAmount
            totalTaxAmount
            imageUrl
            productUrl
        }

        history {
            date
            status
            note
        }
        metadata
        clientData {
            type
            action
            data
        }
        orderShippingMethod
        shipments {
            id
            isShipmentValid
            shippingMethod {
                id
                name
                displayName
                courier {
                    id
                    hasBookInService
                    hasRestrictedAccessService
                }
            }
            orderItems {
                id
                name
                quantity
                imageUrl
            }
            availableShippingMethods {
                id
                name
                displayName
                shippingAmount
                shippingTaxAmount
                packageCount
            }
            estDeliveryDate
            estDispatchDate
            shippingAmount
            shippingTaxAmount
            totalWeight
            services {
                type
                force
            }
        }
        shipmentsInvalid
    }
`;

export const DISCOUNT_COUPON_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment DiscountCouponFields on DiscountCoupon {
        id
        couponCode
        redemptionLimit
        redeemed
        discountId
        salesChannel
        cb
        sb
        co
        so
    }
`;

export const COURIER_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment CourierFields on Courier {
        id
        name
        type
        config {
            propertyId
            property
            value
        }
        cutoffTime
        imageUrl
        unitOfMeasure
        hasBookInService
        hasRestrictedAccessService
        serviceConfig {
            id
            type
            surchargeCode
            websiteEnabled
            customerServiceEnabled
            note
        }
    }
`;
