import React from "react";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import { EcommerceProvider } from "./context/ecommerce";

const Wrapper = ({ element }) => {
    const persistor = persistStore(store);
    return (
        <Provider store={store}>
            <EcommerceProvider>
                <PersistGate loading={null} persistor={persistor}>
                    {element}
                </PersistGate>
            </EcommerceProvider>
        </Provider>
    );
};
export default Wrapper;
